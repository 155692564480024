import messages from '../messages.js';
import * as func from './functions';

class SubmitForm {
    static targetAttrName = 'data-form';

    #element //本体
    #myAttrName //属性名
    #message; // メッセージ
    #submit; // ボタン

    constructor(element, targetAttrName = null) {
        let obj = this;

        obj.element = element;
        obj.myAttrName = targetAttrName ? targetAttrName : obj.constructor.targetAttrName;

        // 識別子
        let key = obj.element.getAttribute(obj.getMyAttrName());
        if (key) {
            obj.message = messages['confirm'][key];
        }

        // submitボタン
        obj.submit = document.querySelector('[' + obj.getMyAttrName('-submit') + '="' + obj.element.id + '"]');

        obj.element.addEventListener('submit', function(e) {
            // e.preventDefault();
            obj.submit.disabled = true;

            if (obj.message && !confirm(obj.message)) {
                obj.submit.disabled = false;
                e.preventDefault();
            } else {
                return true;
            }
        })
    }

    getMyAttrName(additional = '') {
        return this.myAttrName + additional;
    }

    static setup() {
        let attrName, elements;

        attrName = SubmitForm.targetAttrName;
        elements = document.querySelectorAll('[' + attrName + ']');
        for (var i = 0; i < elements.length; i++) {
            new SubmitForm(elements[i]);
        }
    }
}

export default SubmitForm;