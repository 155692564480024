import * as func from './functions';

var Textarea = function(element) {
    this.element = element;
    this.init();
}

Textarea.prototype = {
    init: function() {
        let self = this;

        self.resize();
        self.element.addEventListener('input', function() {
            self.resize();
        });
        self.element.addEventListener('change', function(e) {
            if (!e.target.value) {
                self.resize();
            }
        });
        self.element.addEventListener('show', function() {
            self.resize();
        });
    },

    resize: function() {
        let self = this;

        self.element.style.height = 'auto';
        self.element.style.height = this.element.scrollHeight + 'px';
    }
};

Textarea.setup = function() {
    let inputs = document.querySelectorAll('[data-textarea]');
    Array.prototype.forEach.call(inputs, function(textarea) {
        new Textarea(textarea);
    })
}

Textarea.checkResize = function(element) {
    let inputs = element.querySelectorAll('[data-textarea]');
    Array.prototype.forEach.call(inputs, function(textarea) {
        func.dispatchEvent(textarea, 'show');
    })
}

export default Textarea;