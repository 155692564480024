import * as func from './functions';

func.ready(function() {
    var anchors = document.querySelectorAll("[data-jump]");
    Array.prototype.forEach.call(anchors, function(a, i) {
        a.addEventListener('click', function(e) {
            e.preventDefault();

            if (this.hash == '') {
                // window.scrollTo(0, 0);
                func.skroll(0, 200);
                return;
            }

            var target = document.getElementById(this.hash.replace(/[\/#]/, ''));
            if (target) {
                var position = func.getElementPosition(target);
                func.skroll(position.top, 200);
                // window.scrollTo(position.left, position.top);
            }
        });
    });
});