import * as func from './functions';

func.ready(function() {
    let button = document.querySelector('[data-backtotop]');
    if (!button) {
        return;
    }

    let offsetToShow = button.dataset.backtotop;
    if (!offsetToShow || Number.isInteger(offsetToShow)) {
        offsetToShow = 50;
    } else {
        offsetToShow = parseInt(offsetToShow);
    }

    var process = true;
    window.addEventListener('scroll', function() {
        if (process) {
            process = false;
            setTimeout(function() {
                var scrollOffset = func.getScrollOffset();
                var top = scrollOffset.top;
                if (top > offsetToShow) {
                    if (!func.hasClass(button, '-active')) {
                        func.addClass(button, '-active');
                    }
                } else {
                    func.removeClass(button, '-active');
                }

                process = true;
            }, 100);
        }
    });
});